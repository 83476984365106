



















































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import MaTimePicker from "@/components/MaTimePicker.vue";

import rules from "@/components/account/rules";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { notifyError, notifySuccess } from "@/components/Notification";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import { debounce, omit, omitBy } from "lodash";
import { getAPI } from "@/api/axios-base";
import { RolLabel } from "@/utils/rolesStatus";

const maxDateYear = parseInt(new Date().toISOString().slice(0, 4));
type Subtype = "Surgery" | "Treatment";

export default Vue.extend({
  name: "btn-add-appointment",
  components: {
    MaDatePicker,
    MaTimePicker,
  },
  props: {
    patient: Object,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      loadingData: false,
      loadingSubtype: false,
      appointmenttypes: [],
      loadingProcedures: false,
      formValid: false,
      rules,
      date: "",
      time: "",
      maxDate: new Date(`${maxDateYear + 2}-12-31`).toISOString(),
      editMode: false,
      toEdit: "",

      appointmentDuration: 15,

      proceduresByPatient: [],
      procedureByPatient: null,

      // List of procedures without filter it, to get the procedureId
      patientsProcedures: [],

      patientsLoading: false,
      patientSelected: null,
      query: null,
      loadingQuery: false,
      RolLabel: RolLabel,
      form: {
        initialTime: "",
        endTime: "",
        employeeUuid: "",
        patientUuid: "",
        nomTypeId: "",
        note: "",
        procedureId: null,
      },

      proceduresTypes: [
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ],
      // Old data to edit the appointmet
      // oldData: {},
    };
  },
  computed: {
    ...mapState("crmAppointmentsModule", ["loading"]),
    ...mapState("crmMedicFormModule", ["procedure", "patients"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    ...mapGetters(["isDoctor", "getpatientN"]),
    appoinmentNomType() {
      const temp = (this as any).appointmenttypes.filter(
        (a: any) => a.id == this.form.nomTypeId
      );
      if (temp.length != 0) {
        return temp[0].name;
      }
      return "";
    },
    doctors() {
      if (this.employees.length == 0) {
        return [];
      }
      const filt = this.employees.filter((f: any) => f.rol == "DOCTOR");
      return filt;
    },
    appointmentDurations() {
      if (this.form.nomTypeId == "SURGERY") {
        return [
          { label: "15 min", value: 15 },
          { label: "30 min", value: 30 },
          { label: "1 hour", value: 60 },
          { label: "2 hour", value: 120 },
          { label: "3 hour", value: 180 },
          { label: "4 hour", value: 240 },
          { label: "5 hour", value: 300 },
          { label: "6 hour", value: 360 },
        ];
      }
      return [
        { label: "15 min", value: 15 },
        { label: "30 min", value: 30 },
        { label: "1 hour", value: 60 },
        { label: "2 hour", value: 120 },
      ];
    },

    disableCreate(): boolean {
      return this.formValid == false;
    },
    allowTime(): boolean {
      this.formatDateTime();
      const currentTime = new Date(this.form.initialTime);
      const sheduledTime = new Date(this.form.endTime);

      return moment(sheduledTime).isAfter(currentTime);
    },
    autocompleteLabel(): string {
      if (this.editMode) return this.getpatientN;

      const patientsCount = this.patients.length;

      if (patientsCount > 0) {
        return `${patientsCount} ${this.getpatientN}${
          patientsCount > 1 ? "s" : ""
        } found`;
      }

      return this.getpatientN;
    },

    prodecuresShow() {
      // Tomar los de tipo tratamiento
      let procedures: any[] = this.proceduresByPatient.filter(
        (p: any) => p.activeStatus == "ACTIVE"
      );
      if (this.appoinmentNomType == "SURGERY") {
        procedures = procedures.filter(
          (p: any) => p.surgeryType != null && p.activeStatus == "ACTIVE"
        );
      }
      if (this.appoinmentNomType == "TREATMENT") {
        procedures = procedures.filter(
          (p: any) => p.treatmentType != null && p.activeStatus == "ACTIVE"
        );
      }

      // If have treatment show it
      if (procedures.length > 0) {
        procedures = procedures.map((procedure: any) => {
          const date = moment(procedure.createAt).format("MM-DD-YYYY");
          return {
            name: procedure.surgeryType
              ? procedure.surgeryType.name
              : procedure.treatmentType.name,
            type: procedure.procedure_type,
            date: date,
            procedureId: procedure.id,
          };
        });

        return procedures;
      }
      return [];
    },
  },
  watch: {
    // this: Vue <= Usar esto para evitar errores con typescript

    query: debounce(function query(this: Vue, val: string): void {
      if (val == null || val == "") return;

      (this as any).loadingQuery = true;
      getAPI
        .post("/patient/filterPatients", {
          query: val,
        })
        .then((response: AxiosResponse) => {
          (this as any).patients = response.data;
          (this as any).loadingQuery = false;
        });
    }, 400),
  },
  mounted() {
    this.init();
    this.listAppointmentTypes();
  },
  methods: {
    ...mapActions("crmAppointmentsModule", [
      "actCreateAppointment",
      "actUpdateAppointment",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapMutations("crmAppointmentsModule", ["mutLoading", "mutSelect"]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),

    listAppointmentTypes() {
      this.loadingData = true;
      getAPI(`/appoiment-type/list`)
        .then((res) => {
          this.appointmenttypes = res.data;
          this.loadingData = false;
        })
        .catch((error) => {
          this.loadingData = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async init(): Promise<any> {
      this.loadingData = true;
      if (this.patient) {
        await this.onPatientChange(this.patient.uuid);
      }
      this.patientsLoading = true;
      await this.actListPatientsFilter();
      this.patientsLoading = false;

      await this.actGetEmployees();
      this.loadingData = false;
    },
    resetForm(dialogShow?: boolean) {
      if ((this as any).$refs.timepick) {
        (this as any).$refs.timepick.resetTime();
      }

      this.time = "";
      this.date = "";
      this.form.employeeUuid = "";
      this.form.patientUuid = "";
      this.form.nomTypeId = "";
      this.form.note = "";

      this.form.procedureId = null;

      this.proceduresByPatient = [];
      this.appointmentDuration = 15;

      if ((this as any).$refs.form) {
        (this as any).$refs.form.resetValidation();
      }

      // Si se proporciona el paciente, ponerlo
      if (dialogShow && this.patient !== null && this.patient !== undefined) {
        this.onPatientChange(this.patient.uuid);

        this.form.patientUuid = this.patient.uuid;
      }
    },
    formatDateTime(): void {
      const { date, time } = this;
      const startTime = moment(`${date} ${time}`);

      this.form.initialTime = startTime.utc().toISOString();
      this.form.endTime = startTime
        .add(this.appointmentDuration, "minute")
        .utc()
        .toISOString();
    },
    create() {
      if (this.editMode == false && this.formValid == false) {
        return;
      }

      this.formatDateTime();
      this.loadingData = true;

      if (this.editMode) {
        let body = Object.assign({}, this.form);
        getAPI
          .put("/patient/uptadeDate/" + this.toEdit, body)
          .then(() => {
            this.loadingData = false;
            notifySuccess("Appointment updated successfully");
            setTimeout(() => {
              this.closeDialog();

              this.resetForm();
              this.editMode = false;
              this.$emit("updated");
            }, 500);
          })
          .catch((error) => {
            if (error) {
              let mess = error.response.data.message.toString();

              notifyError(error.response.data, `An error occurred: ${mess}`);
            }

            this.loadingData = false;
            this.mutLoading(false);
          });
      } else {
        let body = Object.assign({}, this.form);

        getAPI
          .post("/patient/createDate", body)
          .then(() => {
            this.loadingData = false;
            this.closeDialog();
            notifySuccess("Appointment created successfully");
            this.$emit("created");
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
            this.loadingData = false;
            this.mutLoading(false);
          });
      }
    },
    async setToEdit(event: any) {
      await this.actListPatientsFilter();
      this.editMode = true;
      this.resetForm(this.dialog);
      this.init().then(() => {
        const start = new Date(event.start).getTime();
        const end = new Date(event.end).getTime();

        this.appointmentDuration = (end - start) / 1000 / 60;

        const eventStart = moment(event.start);

        this.time = eventStart.format("HH:mm");

        const date = moment(event.start);
        this.date = date.format("YYYY-MM-DD"); // yyyy-mm-dd

        const maxDateToEdit = moment(event.start).year();

        this.maxDate = moment(`${maxDateToEdit + 2}-12-31`).toISOString();
        this.form.note = event.data.note;

        const appointmentType = event.data.appoitmentType.id;

        this.toEdit = event.data.uuid;
        this.formatDateTime();

        this.form.nomTypeId = appointmentType;

        // Set patient
        this.form.patientUuid = event.data.patient.uuid;
        this.onPatientChange(event.data.patient.uuid);
        // Set procedure
        this.form.procedureId = event.data.procedure
          ? event.data.procedure.id
          : null;
        this.form.employeeUuid = event.data.doctor.uuid;
        // this.oldData = Object.assign({}, this.form);
      });

      this.dialog = true;
    },
    closeDialog() {
      this.resetForm();
      this.dialog = false;
      this.editMode = false;
    },
    onChange() {
      const procedureSelect: any = this.proceduresByPatient.filter(
        (res: any) => res.id == this.form.procedureId
      );
      if (procedureSelect.length != 0) {
        this.form.employeeUuid = procedureSelect[0].doctor
          ? procedureSelect[0].doctor.uuid
          : "";
      } else {
        this.form.employeeUuid = "";
      }
    },
    changeSelect() {
      const obj = { type: this.form.nomTypeId };
      this.mutSelect(obj);
    },
    async getListNomenclators(type: Subtype | string): Promise<any> {
      this.changeSelect();
      let subtype;

      if (
        type == undefined ||
        type == null ||
        type == "EVALUATION" ||
        type == "FOLLOWUP"
      )
        return;

      switch (type) {
        case "TREATMENT":
          subtype = "Treatment";
          break;
        case "SURGERYORnone":
        case "SURGERYORno1":
        case "SURGERYORno2":
        case "SURGERY":
          subtype = "Surgery";
          break;
      }

      this.loadingSubtype = true;

      this.loadingSubtype = false;
    },

    async AfterCreatedProcedure() {
      await this.onPatientChange(this.procedure.patient.uuid);
      this.form.procedureId = this.procedure.id;
      this.form.employeeUuid = this.procedure.doctor.uuid;
    },

    onPatientChange(patientUuid: string) {
      if (
        patientUuid === null ||
        patientUuid === undefined ||
        patientUuid == ""
      )
        return "";

      this.loadingProcedures = true;
      this.form.procedureId = null;
      getAPI("/patient/getPatient/" + patientUuid)
        .then((response) => {
          const responseData = response.data;
          this.patientSelected = responseData;
          this.proceduresByPatient = responseData.proceduresEntity;
        })
        .finally(() => (this.loadingProcedures = false));
    },
  },
});
